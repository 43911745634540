import { useCallback } from 'react';
import { PanelState, setRotationDegrees } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { useAutoLoadProduct } from './useAutoLoadProduct';
import { useAutoRecognitionBCExperiment } from './useAutoRecognitionBCExperiment';
import { useAutoRecognitionFlyersExperiment } from './useAutoRecognitionFlyersExperiment';
import { useAutoRecognitionTracking } from './useAutoRecognitionTracking';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useProductParams, useUpsellPrice } from '../../../providers';
import {
    getAllItemsInCimdocPanels,
    getImageDimensionInInches,
    getImageURLFromPanel,
    noticeError,
} from '../../../utils';
import { changeSizeStore } from '../store';
import { getAspectRatioSortedProductOptions, getCompatibleOrientationData, getRotationAngle } from '../utils';

export const useAutoRecognition = () => {
    const {
        cimDocStore: { panels },
        layoutStore: { visiblePanels },
        executeCommand,
    } = useDesignEngine();
    const {
        sizeAttribute,
        setupAutoRecognition,
        resetAutoRecognition,
        setShowAutoRecognitionLoader,
        orientationSizesMap,
        compatibleOrientations,
    } = changeSizeStore;
    const { auth: vpAuth } = useIdentityContext();
    const { locale } = useProductParams();
    const { productData } = useProductConfiguration();
    const { loadAutoRecognitionProduct } = useAutoLoadProduct();
    const { trackAutoRecognitionTriggered } = useAutoRecognitionTracking();
    const { surfaceUpsells } = useUpsellPrice();

    const authorizationHeader = vpAuth.getAuthorizationHeader();
    const { isAutoRecognitionBCEnabled } = useAutoRecognitionBCExperiment();
    const { isAutoRecognitionFlyersEnabled } = useAutoRecognitionFlyersExperiment();
    const isAutoRecognitionEnabled = isAutoRecognitionBCEnabled || isAutoRecognitionFlyersEnabled;

    const applyAutoRecognition = useCallback(
        async (panel: PanelState) => {
            try {
                const imageURL = getImageURLFromPanel(visiblePanels[0]);
                if (!productData || !imageURL || !authorizationHeader) {
                    return;
                }

                setShowAutoRecognitionLoader(true);

                const {
                    customerSelectedOptions,
                    selectedOptions,
                    studioConfiguration: { productMinDpi },
                } = productData;

                const [{ orientation, direction, angle }, imageDimensions] = await Promise.all([
                    getCompatibleOrientationData(
                        imageURL,
                        compatibleOrientations,
                        selectedOptions,
                        authorizationHeader
                    ),
                    getImageDimensionInInches(imageURL, authorizationHeader, productMinDpi),
                ]);

                const { bestFits } = await getAspectRatioSortedProductOptions(
                    orientation,
                    imageDimensions,
                    orientationSizesMap,
                    sizeAttribute,
                    locale,
                    productData
                );

                const preSelected = orientationSizesMap[orientation].includes(customerSelectedOptions[sizeAttribute])
                    ? customerSelectedOptions[sizeAttribute]
                    : bestFits[0];

                let newProductOptions;
                if (surfaceUpsells) {
                    const surfaceUpsell = surfaceUpsells[panel.panelProperties.name];
                    if (surfaceUpsell) {
                        newProductOptions = { [surfaceUpsell.optionName]: surfaceUpsell.colorOption };
                    }
                }

                const autoRecognitionData = {
                    bestFits,
                    preSelected,
                    detectedOrientation: orientation,
                    productOptions: newProductOptions,
                };

                if (angle && direction) {
                    executeCommand(setRotationDegrees, {
                        id: visiblePanels[0].items[0].id,
                        degrees: getRotationAngle(direction, angle),
                    });
                }

                setupAutoRecognition(autoRecognitionData);
                await loadAutoRecognitionProduct(autoRecognitionData);
                trackAutoRecognitionTriggered({ autoRecognitionData });
            } catch (error) {
                resetAutoRecognition();
                noticeError(error, {
                    method: 'applyAutoRecognition',
                });
            } finally {
                setShowAutoRecognitionLoader(false);
            }
        },
        [
            visiblePanels,
            sizeAttribute,
            productData,
            orientationSizesMap,
            compatibleOrientations,
            authorizationHeader,
            locale,
            loadAutoRecognitionProduct,
        ]
    );

    const handleApplyAutoRecognition = async (panel: PanelState) => {
        if (isAutoRecognitionEnabled) {
            await applyAutoRecognition(panel);
        }
    };

    const handleResetAutoRecognition = () => {
        if (getAllItemsInCimdocPanels(panels).length === 0 && isAutoRecognitionEnabled) {
            resetAutoRecognition();
        }
    };

    return { handleApplyAutoRecognition, handleResetAutoRecognition };
};
