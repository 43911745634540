import { CimDoc } from '@design-stack-vista/cdif-types';
import { v4 as uuid } from 'uuid';
import { ARTWORK_GENERATION_API_KEY, REQUESTOR } from '../config/constant';
import { fetchWrapper } from '../utils';

const CUSTOMIZATION_TRANSFER_URL = 'https://artworkgeneration.cimpress.io/';

export async function getResizedDocumentByDesignViews(
    designDocument: CimDoc,
    designViewsUrl: string,
    signal?: AbortSignal
): Promise<CimDoc> {
    const response = await fetchWrapper(`${CUSTOMIZATION_TRANSFER_URL}api/v3/adaptation:resize`, {
        method: 'POST',
        requestInitiator: 'getResizedDocumentByDesignViews',
        body: {
            requestor: REQUESTOR,
            document: designDocument,
            surfaceSpecificationUrl: designViewsUrl,
            apiKey: ARTWORK_GENERATION_API_KEY,
        },
        headers: {
            'x-caller-id': REQUESTOR,
            'x-correlation-id': uuid(),
        },
        signal,
    });

    return response;
}
