import { useQuadExperiment } from '../../../hooks/useQuadExperiment';
import { useAbTestContext } from '../../../providers/ABTestProvider';
import { getExperimentProperties } from '../../../utils/getExperimentProperties';

const AUTO_RECOGNITION_FLYERS_EXP_KEY = 'auto_orientation_for_flyers';
const TEST_VARIATION = 'test';

export const useAutoRecognitionFlyersExperiment = () => {
    const experiment = useQuadExperiment();
    const ABTest = useAbTestContext();
    let isAutoRecognitionFlyersEnabled = false;
    if (ABTest) {
        const { experimentKey, variationKey } = getExperimentProperties(experiment, ABTest);
        isAutoRecognitionFlyersEnabled =
            experimentKey === AUTO_RECOGNITION_FLYERS_EXP_KEY && variationKey === TEST_VARIATION;
    }
    return { isAutoRecognitionFlyersEnabled };
};
