import { useQuadExperiment } from '../../../hooks/useQuadExperiment';
import { useAbTestContext } from '../../../providers/ABTestProvider';
import { getExperimentProperties } from '../../../utils/getExperimentProperties';

const AUTO_RECOGNITION_BC_EXP_KEY = 'auto_orientation_for_bc';
const TEST_VARIATION = 'test';

export const useAutoRecognitionBCExperiment = () => {
    const experiment = useQuadExperiment();
    const ABTest = useAbTestContext();
    let isAutoRecognitionBCEnabled = false;
    if (ABTest) {
        const { experimentKey, variationKey } = getExperimentProperties(experiment, ABTest);
        isAutoRecognitionBCEnabled = experimentKey === AUTO_RECOGNITION_BC_EXP_KEY && variationKey === TEST_VARIATION;
    }
    return { isAutoRecognitionBCEnabled };
};
