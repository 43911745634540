import { action, makeObservable, observable } from 'mobx';
import { ChoiceGroupPriceDetails } from '../../../types/price';
import { getAttributeValuesFromChoiceGroups, ProductChoiceGroups } from '../../Flexibility/common';
import { AutoRecognitionDataType, ChangeSizeData, OrientationsSizesMap } from '../types';

class ChangeSizeStore {
    // ChangeSize Data
    @observable activeSize = '';
    @observable activeOrientation = '';
    @observable sizeAttribute = '';
    @observable isSizeFlexible = false;
    @observable isOrientationFlexible = false;
    @observable isChangeSizeModalOpen = false;
    @observable compatibleOrientations: string[] = [];
    @observable.struct changeSizePrices: ChoiceGroupPriceDetails = {};
    @observable.struct orientationSizesMap: OrientationsSizesMap = {};
    @observable.struct changeSizeChoiceGroups: ProductChoiceGroups = {};
    @observable activeChoiceGroup: Record<string, string> = {};
    @observable sizesList: string[] = [];
    // AutoRecognition Data
    @observable.struct autoRecognitionData: AutoRecognitionDataType | null = null;
    @observable showAutoRecognitionLoader = false;

    constructor() {
        makeObservable(this);
    }

    // ChangeSize Actions
    @action.bound
    setChangeSizeData({
        sizeAttribute,
        compatibleOrientations,
        orientationSizesMap,
        changeSizeChoiceGroups,
    }: ChangeSizeData) {
        this.sizeAttribute = sizeAttribute;
        this.compatibleOrientations = compatibleOrientations;
        this.orientationSizesMap = orientationSizesMap;
        this.changeSizeChoiceGroups = changeSizeChoiceGroups;
        this.isOrientationFlexible = compatibleOrientations.length > 1;
        this.isSizeFlexible = Object.keys(changeSizeChoiceGroups).length >= 2;
        this.sizesList =
            compatibleOrientations.length > 1
                ? []
                : getAttributeValuesFromChoiceGroups(sizeAttribute, changeSizeChoiceGroups);
    }

    @action.bound
    setChangeSizePrices(changeSizePrices: ChoiceGroupPriceDetails) {
        this.changeSizePrices = changeSizePrices;
    }

    @action.bound
    resetChangeSizeStore() {
        this.sizeAttribute = '';
        this.isSizeFlexible = false;
        this.isOrientationFlexible = false;
        this.compatibleOrientations = [];
        this.orientationSizesMap = {};
        this.changeSizeChoiceGroups = {};
        this.changeSizePrices = {};
    }

    @action.bound
    setOpenChangeSizeModal(state: boolean) {
        this.isChangeSizeModalOpen = state;
    }

    @action.bound
    setActiveSize(size: string) {
        this.activeSize = size;
    }

    @action.bound
    setActiveOrientation(orientation: string) {
        if (this.isOrientationFlexible) {
            this.activeOrientation = orientation;
            this.sizesList = this.orientationSizesMap[orientation];
        }
    }

    // AutoRecognition Actions
    @action.bound
    setShowAutoRecognitionLoader(status: boolean) {
        this.showAutoRecognitionLoader = status;
    }

    @action.bound
    setupAutoRecognition(autoRecognitionData: AutoRecognitionDataType) {
        this.autoRecognitionData = autoRecognitionData;
        this.setActiveSize(autoRecognitionData.preSelected);
        this.setActiveOrientation(autoRecognitionData.detectedOrientation);
    }

    @action.bound
    resetAutoRecognition() {
        this.autoRecognitionData = null;
    }
}

export const changeSizeStore = new ChangeSizeStore();
