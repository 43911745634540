import { calculateCoverPosition, Measurement } from '@design-stack-ct/utility-core';
import { CimDoc, ImageItem, Panel } from '@design-stack-vista/cdif-types';
import { resizeItem, ResizeItemArgs } from '@design-stack-vista/cimdoc-state-manager';
import { generateDesignViewsURL } from '../../../../api/designViews';
import { getResizedDocumentByDesignViews } from '../../../../api/transformationService';
import { getItemPositionFitOnRotation } from '../../../../utils/getItemPositionFitOnRotation';
import { ResizeDocumentProps } from '../types';

const getItemResizeArgs = (imageItem: ImageItem, panel: Panel): ResizeItemArgs => {
    const { rotationAngle } = imageItem;
    const rotation = Number(rotationAngle ?? 0);

    const panelDimension = {
        width: new Measurement(panel.width).mm,
        height: new Measurement(panel.height).mm,
    };

    const imageDimensions = {
        width: new Measurement(imageItem.position.width).mm,
        height: new Measurement(imageItem.position.height).mm,
    };

    const coverPosition =
        rotation === 90 || rotation === 270
            ? calculateCoverPosition(
                  {
                      width: imageDimensions.height,
                      height: imageDimensions.width,
                  },
                  panelDimension
              )
            : calculateCoverPosition(
                  {
                      width: imageDimensions.width,
                      height: imageDimensions.height,
                  },
                  panelDimension
              );

    const targetBoundingBox = {
        x: coverPosition.x,
        y: coverPosition.y,
        width: coverPosition.width,
        height: coverPosition.height,
    };

    const aspectRatio = imageDimensions.width / imageDimensions.height;

    const fitToBleedDimensions = getItemPositionFitOnRotation(targetBoundingBox, aspectRatio, rotation);

    const roundedDimensions = {
        id: imageItem.id,
        newHeight: fitToBleedDimensions.height,
        newWidth: fitToBleedDimensions.width,
        newX: fitToBleedDimensions.x,
        newY: fitToBleedDimensions.y,
    };

    return roundedDimensions;
};

const hasMultipleItems = (panel: Panel) => {
    const panelImages = panel.images ?? [];
    const panelShapes = panel.shapes ?? [];
    const panelTextAreas = panel.textAreas ?? [];
    const panelItemReferences = panel.itemReferences ?? [];

    const panelItems = [...panelImages, ...panelShapes, ...panelTextAreas, ...panelItemReferences];

    return panelItems.length > 1;
};

export function transformDocument(cimDoc: CimDoc) {
    const {
        document: { panels },
    } = cimDoc;

    panels.forEach((panel) => {
        const panelImages = panel.images ?? [];
        const isMultipleItems = hasMultipleItems(panel);

        if (panelImages.length && !isMultipleItems) {
            resizeItem(cimDoc, getItemResizeArgs(panelImages[0], panel));
        }
    });
}

export async function getResizedDocument(
    { designDocument, productKey, productVersion, selectedOptions, locale }: ResizeDocumentProps,
    signal?: AbortSignal
) {
    const designViewsURL = generateDesignViewsURL(productKey, productVersion, selectedOptions, locale);
    const resizedDocumentSources = await getResizedDocumentByDesignViews(designDocument, designViewsURL, signal);
    return resizedDocumentSources;
}
