import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useHandleAdjustContrastExperience } from '../features/AdjustContrast';
import { backDesignTemplateStore } from '../features/BackDesignTemplate';
import { useAutoRecognition } from '../features/ChangeSize';
import { postCardStore } from '../features/PostCard/PostCardStore';
import { useResetReviewInstructions } from '../features/PremiumFinish';
import { usePremiumFinishModal } from '../providers';

export const useHandleCustomExperiences = () => {
    const { handleTemplateExperience } = backDesignTemplateStore;
    const { handlePostCardExperience } = postCardStore;
    const { handleAdjustContrastExperience } = useHandleAdjustContrastExperience();
    const { openPremiumFinishModalOnUpload } = usePremiumFinishModal();
    const { handleApplyAutoRecognition, handleResetAutoRecognition } = useAutoRecognition();
    const { handlePremiumFinishReviewInstructions } = useResetReviewInstructions();

    const handleAddImageExperiences = async (panel: PanelState): Promise<void> => {
        // trigger auto recognition experience
        await handleApplyAutoRecognition(panel);

        // trigger premium finish experience
        openPremiumFinishModalOnUpload(panel);

        // trigger adjust contrast experience
        handleAdjustContrastExperience(panel);

        // trigger postcard experience
        handlePostCardExperience();
    };

    const handleDeleteImageExperiences = (panel: PanelState): void => {
        handleResetAutoRecognition();
        handlePremiumFinishReviewInstructions(panel);
    };

    const handleDeleteTemplateExperiences = (): void => {
        handleTemplateExperience();
    };

    return {
        handleAddImageExperiences,
        handleDeleteImageExperiences,
        handleDeleteTemplateExperiences,
    };
};
