import React from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import {
    LocalizationProvider,
    UploadManagerProvider,
    useUploadConfiguration,
} from '@design-stack-vista/upload-components';
import { SubBrands, Tenants, VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import { recentImageStore } from './Store/RecentImageStore';
import { useUploadModal } from './UploadModalProvider';
import { useNotificationFramework } from '../components/Notification';
import { APPLICATION_NAME } from '../config/constant';
import { useBackDesignTemplate } from '../features/BackDesignTemplate';
import { useDebugFlag } from '../features/Debug/useDebugFlag';
import { useAutoMapTrackEvent } from '../hooks/useAutoMapTrackEvent';
import { useFolderName } from '../hooks/useFolderName';
import { useUploadPanels } from '../hooks/useUploadPanel';
import { useUploadTracking } from '../hooks/useUploadTracking';
import {
    canBeAutoMapped,
    checkIsAssetProcessed,
    getNumPages,
    getSessionStorageKey,
    getUploadErrorMessage,
    isFileFusionCompatible,
    logUploadError,
    SESSION_STORAGE_KEY,
    UploadError,
} from '../utils';
import { text } from '../utils/localization';

interface UploadProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

export const UploadProvider = observer((props: UploadProviderProps) => {
    const { children } = props;
    const {
        idaStore: { activeDesignPanelId },
        layoutStore: { visiblePanelIds },
        cimDocStore: { panels },
    } = useDesignEngine();
    const folderName = useFolderName();
    const { isFlagEnabled } = useDebugFlag();
    const { auth: vpAuth } = useIdentityContext();
    const { supportedFileTypes } = useUploadConfiguration();
    const { triggerAutoMappedEvent } = useAutoMapTrackEvent();
    const { trackMultipleUpload, trackAssetUpload } = useUploadTracking();
    const { onAddImageToPanel, onInstantUploadToPanel } = useUploadPanels();
    const { notifyCustomer, notificationHistory } = useNotificationFramework();
    const { showUploadModal, setValidationMessage, setShowUploadLoader } = useUploadModal();
    const { setTemplatePreviewsAndColors } = useBackDesignTemplate();

    const activePanelId = activeDesignPanelId ?? visiblePanelIds[0];
    const isInstantUploadEnabled = isFlagEnabled('quad_instant_upload');

    const authProvider = {
        getAuthHeaders: async () => ({
            Authorization: vpAuth.getAuthorizationHeader(),
        }),
    };

    const displayErrorNotification = (uploadError: UploadError) => {
        const { errorMessage, title } = uploadError;
        notifyCustomer(
            {
                notificationType: 'error',
                messageToShowCustomer: errorMessage,
                notificationTitle: title,
            },
            true
        );
        setValidationMessage(null);
    };

    const onUploadError = (error: Error) => {
        const uploadError = getUploadErrorMessage(error, supportedFileTypes.fileExtensionsAsString);
        const { errorMessage, validationMessage } = uploadError;
        // Display error toast if upload modal closed or display alert inside upload modal
        if (!showUploadModal) {
            // Prevent displaying of the same error multiple times
            if (notificationHistory.length > 0) {
                const lastNotification = notificationHistory[notificationHistory.length - 1];
                const { notificationType, messageToShowCustomer } = lastNotification;
                if (notificationType !== 'error' && messageToShowCustomer !== errorMessage) {
                    displayErrorNotification(uploadError);
                }
            } else {
                displayErrorNotification(uploadError);
            }
        } else {
            setValidationMessage(validationMessage);
        }

        // log error to new relic
        logUploadError(error, activeDesignPanelId);
    };

    const handleAutoMapping = async (asset: VistaAsset): Promise<void> => {
        const totalNoOfPDFPages = getNumPages(asset);

        let pageNo = 0;
        for (; pageNo < totalNoOfPDFPages && pageNo < panels.length; pageNo++) {
            const isLast = pageNo === totalNoOfPDFPages - 1 || pageNo === panels.length - 1;
            await onAddImageToPanel(panels[pageNo].id, asset, pageNo + 1, isLast);
        }

        triggerAutoMappedEvent({ numberOfPagesAutoMapped: pageNo, totalNumberOfPDFPages: totalNoOfPDFPages });
    };

    // In case of pdf do an auto mapping if possible otherwise do normal image addition
    const handleSingleUpload = async (upload: Promise<void | VistaAsset>, rawFile: File): Promise<void> => {
        const isInstantUploadEligible =
            isInstantUploadEnabled &&
            !getSessionStorageKey(SESSION_STORAGE_KEY.DISABLE_INSTANT_UPLOAD) &&
            isFileFusionCompatible(rawFile);

        if (isInstantUploadEligible) {
            /** instant upload */
            setShowUploadLoader(false);
            await onInstantUploadToPanel(activePanelId, upload, rawFile);
        } else {
            setShowUploadLoader(true);
            const asset = await Promise.resolve(upload);
            if (asset) {
                // works as polling: waits for the first function to return true and then triggers the effect(second function)
                when(
                    () => checkIsAssetProcessed(asset) && !recentImageStore.isPreviewLoading,
                    async () => {
                        if (recentImageStore.isPreviewFailed) {
                            return;
                        }

                        if (canBeAutoMapped(asset, panels, activePanelId)) {
                            await handleAutoMapping(asset);
                        } else {
                            await onAddImageToPanel(activePanelId, asset);
                        }
                        const isFirstPanel = activePanelId === panels[0].panelProperties.id;
                        if (isFirstPanel) {
                            await setTemplatePreviewsAndColors();
                        }
                    }
                );
            }
        }
    };

    // currently we only track when user uploads multiple files
    const handleMultipleUploads = async (assets: Promise<void | VistaAsset>[]) => {
        await trackMultipleUpload(assets);
        recentImageStore.setIsPreviewLoading(false);
    };

    const onUpload = async (assets: Promise<void | VistaAsset>[], rawFiles: File[]): Promise<void> => {
        recentImageStore.setIsPreviewFailed(false);
        recentImageStore.setIsPreviewLoading(true);
        if (assets.length > 1) {
            // When user uploads multiple files at a time
            await handleMultipleUploads(assets);
        } else {
            // When user uploads a single file
            const asset = assets[0];
            const rawFile = rawFiles[0];

            await handleSingleUpload(asset, rawFile);
        }

        await trackAssetUpload(assets);
    };

    return (
        <UploadManagerProvider
            authProvider={authProvider}
            subBrand={SubBrands.VistaPrint}
            experience={APPLICATION_NAME}
            writeTenant={Tenants.VistaPrint}
            fetchLimit={10}
            folderName={folderName}
            onUpload={onUpload}
            onError={onUploadError}
            assetsExpireOn={'never'}
        >
            <LocalizationProvider
                localizedValues={{
                    deleteButtonTitle: text('deleteButton'),
                    deletingLabel: text('deletingLabel'),
                    assetInUseLabel: text('assetInUseLabel'),
                    loadingLabel: text('loadingLabel'),
                    uploadingLabel: text('uploadingLabel'),
                    deleteConfirmationKeys: {
                        closeButton: text('closeDeleteButton'),
                        dialogTitle: text('deleteImageDialogTitle'),
                        dialogMessage: text('confirmDelete'),
                        cancelButton: text('cancelButton'),
                        confirmButton: text('confirmButton'),
                    },
                }}
            >
                {children}
            </LocalizationProvider>
        </UploadManagerProvider>
    );
});
