import { useCallback } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { useProductConfiguration } from '../../../../hooks/calcifer';
import { useProductParams } from '../../../../providers';
import { noticeError } from '../../../../utils';
import { useWaitForInstantUpload } from '../../../InstantUpload';
import { getProductOptionsList, getResizedDocument, transformDocument } from '../utils';

export const useFlexibilityCimDocs = () => {
    const { locale } = useProductParams();
    const designEngine = useDesignEngine();
    const { auth: vpAuth } = useIdentityContext();
    const { productData } = useProductConfiguration();
    const { waitForInstantUpload } = useWaitForInstantUpload();

    const authorizationHeader = vpAuth.getAuthorizationHeader();

    const getFlexibilityCimDocs = useCallback(
        async (productAttributesList: Record<string, string>[], abortSignal?: AbortSignal) => {
            try {
                if (!productData || !authorizationHeader) {
                    return [];
                }

                await waitForInstantUpload({ abortSignal });

                const designDocument = await designEngine.getCimDoc();

                const { productKey, productVersion } = productData;
                const productOptionsList = getProductOptionsList(productData, productAttributesList);

                const cimDocPromises = productOptionsList.map((productOptions) =>
                    getResizedDocument(
                        {
                            designDocument,
                            productKey,
                            productVersion,
                            selectedOptions: productOptions,
                            locale,
                        },
                        abortSignal
                    )
                );

                const cimDocList = await Promise.all(cimDocPromises);

                return cimDocList;
            } catch (error) {
                noticeError(error, {
                    method: 'getFlexibilityCimDocs - useFlexibilityCimDocs',
                });
                return [];
            }
        },
        [productData, authorizationHeader, locale, designEngine]
    );

    const getTransformedFlexibilityCimDocs = useCallback(
        async (productAttributesList: Record<string, string>[], abortSignal?: AbortSignal) => {
            const cimDocList = await getFlexibilityCimDocs(productAttributesList, abortSignal);
            cimDocList.forEach((cimDoc) => transformDocument(cimDoc));
            return cimDocList;
        },
        [getFlexibilityCimDocs]
    );

    return {
        getFlexibilityCimDocs,
        getTransformedFlexibilityCimDocs,
    };
};
