import { CompatibleOptionsEntry, getProductSupportedAttributes } from '../../../api/catalog';
import { ProcessType, ProductAttributes } from '../../../config/constant';
import { ProductData } from '../../../hooks/calcifer/useProductConfiguration';
import { DiscountPricingDetails } from '../../../types/price';
import { StudioSecondaryConfigResponse } from '../../../types/secondaryConfigTypes';
import { noticeError } from '../../../utils';
import { text } from '../../../utils/localization';
import {
    getCompatibleChoiceGroups,
    getOptionsWithoutAttribute,
    mapDesignAttributeToProductOption,
    ProductChoice,
    ProductChoiceGroups,
} from '../../Flexibility/common';
import { ChangeSizeData, OrientationsSizesMap } from '../types';

const getCompatibleOrientations = async (
    productKey: string,
    productVersion: number,
    selectedOptions: Record<string, string>,
    sizeAttribute: string,
    authHeader: string,
    abortSignal?: AbortSignal
): Promise<string[]> => {
    const productOptionsWithoutSize = getOptionsWithoutAttribute(selectedOptions, sizeAttribute);

    const compatibleOptions = await getProductSupportedAttributes(
        productKey,
        productOptionsWithoutSize,
        productVersion,
        authHeader,
        abortSignal
    );
    return compatibleOptions.find((option) => option.name === ProductAttributes.Orientation)?.values ?? [];
};

const getChoiceGroupsWithOrientationValue = (
    compatibleOptions: CompatibleOptionsEntry[],
    sizeAttribute: string,
    orientation: string
): ProductChoiceGroups => {
    const compatibleAttributeValues = compatibleOptions.find((option) => option.name === sizeAttribute)?.values;

    return (
        compatibleAttributeValues?.reduce((acc, value) => {
            return {
                ...acc,
                [`${orientation}-${value}`]: {
                    [sizeAttribute]: value,
                    [ProductAttributes.Orientation]: orientation,
                },
            };
        }, {}) ?? {}
    );
};

const getChoiceGroupsWithOrientation = async (
    productKey: string,
    productVersion: number,
    selectedOptions: Record<string, string>,
    sizeAttribute: string,
    orientationList: string[],
    authHeader: string,
    abortSignal?: AbortSignal
): Promise<ProductChoiceGroups> => {
    const productOptionsWithoutSize = getOptionsWithoutAttribute(selectedOptions, sizeAttribute);

    const productOptionsList = orientationList.map((orientation) => {
        return {
            ...productOptionsWithoutSize,
            [ProductAttributes.Orientation]: orientation,
        };
    });

    const compatibleOptionsPromises = productOptionsList.map((productOptions) =>
        getProductSupportedAttributes(productKey, productOptions, productVersion, authHeader, abortSignal)
    );

    const compatibleOptionsList = await Promise.all(compatibleOptionsPromises);

    return orientationList.reduce((acc, orientation, index) => {
        return {
            ...acc,
            ...getChoiceGroupsWithOrientationValue(compatibleOptionsList[index], sizeAttribute, orientation),
        };
    }, {});
};

const getChangeSizeChoiceGroups = async (
    productKey: string,
    productVersion: number,
    selectedOptions: Record<string, string>,
    sizeAttribute: string,
    compatibleOrientations: string[],
    compatibleOptions: CompatibleOptionsEntry[],
    authHeader: string,
    abortSignal?: AbortSignal
): Promise<ProductChoiceGroups> => {
    if (compatibleOrientations.length > 1) {
        return await getChoiceGroupsWithOrientation(
            productKey,
            productVersion,
            selectedOptions,
            sizeAttribute,
            compatibleOrientations,
            authHeader,
            abortSignal
        );
    }
    return getCompatibleChoiceGroups(compatibleOptions, sizeAttribute);
};

const getOrientationSizeMap = (
    compatibleOrientations: string[],
    changeSizeChoiceGroups: ProductChoiceGroups,
    sizeAttribute: string
): OrientationsSizesMap => {
    const productAttributesLists = Object.values(changeSizeChoiceGroups);

    return compatibleOrientations.reduce((acc, orientation) => {
        const orientationSizes = productAttributesLists
            .filter((productAttributes) => productAttributes[ProductAttributes.Orientation] === orientation)
            .map((productAttributes) => productAttributes[sizeAttribute]);

        return {
            ...acc,
            [orientation]: orientationSizes,
        };
    }, {});
};

export const getChangeSizeData = async (
    productData: ProductData,
    secondaryConfig: StudioSecondaryConfigResponse,
    authHeader: string,
    abortSignal?: AbortSignal
): Promise<ChangeSizeData> => {
    try {
        const { designAttributes, compatibleOptions } = secondaryConfig;
        const { productKey, productVersion, selectedOptions } = productData;

        const sizeAttribute = mapDesignAttributeToProductOption(designAttributes, ProductAttributes.Size);

        const compatibleOrientations = await getCompatibleOrientations(
            productKey,
            productVersion,
            selectedOptions,
            sizeAttribute,
            authHeader,
            abortSignal
        );

        const changeSizeChoiceGroups = await getChangeSizeChoiceGroups(
            productKey,
            productVersion,
            selectedOptions,
            sizeAttribute,
            compatibleOrientations,
            compatibleOptions,
            authHeader,
            abortSignal
        );

        const orientationSizesMap = getOrientationSizeMap(
            compatibleOrientations,
            changeSizeChoiceGroups,
            sizeAttribute
        );

        return {
            sizeAttribute,
            compatibleOrientations,
            changeSizeChoiceGroups,
            orientationSizesMap,
        };
    } catch (error) {
        noticeError(error, {
            method: 'getChangeSizeData - useGetChangeSizeData',
        });

        throw new Error('Failed to get Change size initialization data');
    }
};

export const checkIsChangeSizeCompatible = (productData: ProductData) => {
    const {
        studioConfiguration: { showChangeSize },
        designViews: { designViews },
    } = productData;

    return !!showChangeSize && designViews[0].processType === ProcessType.OffsetOrDigital;
};

export const getChangeSizeChoiceKey = (isOrientationFlexible: boolean, orientation: string, size: string): string => {
    return isOrientationFlexible ? `${orientation}-${size}` : `${size}`;
};

export const getChangeSizeNotification = (
    productData: ProductData,
    productAttributes: ProductChoice,
    priceData: DiscountPricingDetails,
    sizeAttribute: string,
    quantity: number,
    getTranslatedOptionName: (designAttributeName: string, designAttributeValue: string) => string
): string => {
    const { totalDiscountPrice: price } = priceData;
    const { productName, selectedOptions } = productData;
    const currentOrientation = selectedOptions[ProductAttributes.Orientation];
    const newOrientation = productAttributes[ProductAttributes.Orientation];
    const orientation =
        currentOrientation !== newOrientation
            ? getTranslatedOptionName(ProductAttributes.Orientation, newOrientation)
            : '';
    const attributeValue = getTranslatedOptionName(ProductAttributes.Size, productAttributes[sizeAttribute]);

    return text('changedProduct', {
        productName: productName,
        orientation,
        attributeValue,
        price,
        quantity,
    });
};
