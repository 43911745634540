export const FULL_ROTATION = 360;

export enum Angle {
    Zero = 0,
    Ninety = 90,
    HundredEighty = 180,
}

export enum Directions {
    Clockwise = 'clockwise',
    AntiClockwise = 'anticlockwise',
    NoRotation = 'noRotation',
}

export enum ImageOrientations {
    Horizontal = 'Horizontal',
    Vertical = 'Vertical',
    HorizontalRotation = 'HorizontalRotation',
    VerticalRotation = 'VerticalRotation',
}

export const autoRecognitionCases = {
    1: 'Case 1',
    2: 'Case 2',
    3: 'Case 3',
    4: 'Case 4',
};
