import React from 'react';
import { FlexBox } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { Orientations } from './Orientations';
import { Sizes } from './Sizes';

export const ChangeSizePanelContent = observer(() => (
    <FlexBox flexDirection="column">
        <Orientations />
        <Sizes />
    </FlexBox>
));
