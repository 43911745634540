import { CimDoc } from '@design-stack-vista/cdif-types';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { useActivePanel } from './useActivePanel';
import { useAddItemEvents } from './useAddItemEvents';
import { useRemoveItemEvents } from './useRemoveItemEvents';
import { applyColorToPanel, applyDesignTemplateToPanel, backDesignTemplateStore } from '../features/BackDesignTemplate';
import { useUploadAsset } from '../features/quad/recipes/useUploadAsset';
import { hasSingleImageItem, noticeError } from '../utils';

export const useUploadPanels = () => {
    const designEngine = useDesignEngine();
    const { cimDocStore } = designEngine;
    const { panels } = cimDocStore;
    const activePanel = useActivePanel();
    const { onAddImage, onInstantUpload, onRemoveItems } = useUploadAsset();
    const { preAddImageEvents, postAddItemEvents } = useAddItemEvents();
    const { preRemoveItemEvents, postRemoveItemEvents } = useRemoveItemEvents();
    const { selectedDesignTemplate } = backDesignTemplateStore;

    const onAddImageToPanel = async (
        panelId: string,
        asset: VistaAsset,
        pageNum?: number,
        isLastPage = true
    ): Promise<void> => {
        try {
            const panel = panels.find((panel) => panel.id === panelId);
            if (!panel) {
                throw Error('Cannot find target panel');
            }
            const showPriceUpdateNotification = !panel.items.length;

            preAddImageEvents();
            await onAddImage(panel, asset, pageNum);
            if (isLastPage) {
                await postAddItemEvents(panel, true, showPriceUpdateNotification);
            }

            return;
        } catch (error) {
            noticeError(error, {
                method: 'useUploadPanels - onAddImageToPanel',
            });
            return;
        }
    };

    const onInstantUploadToPanel = async (panelId: string, asset: Promise<void | VistaAsset>, rawFile: File) => {
        try {
            const panel = panels.find((panel) => panel.id === panelId);
            if (!panel) {
                throw Error('Cannot find target panel');
            }
            const hasItems = panel.items.length;

            preAddImageEvents();
            await onInstantUpload(panel, asset, rawFile);
            await postAddItemEvents(panel, true, !hasItems);

            return;
        } catch (error) {
            noticeError(error, {
                method: 'useUploadPanels - onInstantUploadToPanel',
            });
            return;
        }
    };

    const onAddShapeToPanel = async (panelId: string, color: string) => {
        try {
            const panel = panels.find((panel) => panel.id === panelId);
            if (!panel) {
                throw Error('Cannot find target panel');
            }
            const showPriceUpdateNotification = !panel.items.length;

            applyColorToPanel({ designEngine, panel, color });
            await postAddItemEvents(panel, false, showPriceUpdateNotification);
        } catch (error) {
            noticeError(error, {
                method: 'useUploadPanels - onAddShapeToPanel',
            });
            return;
        }
    };

    const onAddDesignTemplateToPanel = async (panelId: string, designCimDoc: CimDoc) => {
        try {
            const panel = panels.find((panel) => panel.id === panelId);
            if (!panel) {
                throw Error('Cannot find target panel');
            }
            const showPriceUpdateNotification = !panel.items.length && !selectedDesignTemplate;

            applyDesignTemplateToPanel({ designEngine, panel, designCimDoc });
            await postAddItemEvents(panel, false, showPriceUpdateNotification);
        } catch (error) {
            noticeError(error, {
                method: 'useUploadPanels - onAddDesignTemplateToPanel',
            });
            return;
        }
    };

    const onRemoveItemFromPanel = () => {
        if (!activePanel) return;

        try {
            const panel = panels.find((panel) => panel.id === activePanel.id);
            if (!panel) {
                throw Error('Cannot find target panel');
            }

            const items = panel.items;
            if (!items.length) {
                return;
            }

            const isSingleImageItem = hasSingleImageItem(panel);

            preRemoveItemEvents();
            onRemoveItems(panel);
            postRemoveItemEvents(panel, isSingleImageItem);
        } catch (error) {
            noticeError(error, {
                method: 'useUploadPanels - onRemoveItemFromPanel',
            });
            return;
        }
    };

    return {
        onAddImageToPanel,
        onInstantUploadToPanel,
        onAddShapeToPanel,
        onAddDesignTemplateToPanel,
        onRemoveItemFromPanel,
    };
};
