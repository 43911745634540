import { getProcessedImageMindInfo } from './imageMindInfo';
import { ProductAttributes } from '../../../config/constant';
import { ProductData } from '../../../hooks/calcifer/useProductConfiguration';
import { ChoiceGroupPriceDetails } from '../../../types/price';
import { text } from '../../../utils/localization';
import { Angle, autoRecognitionCases, Directions, FULL_ROTATION } from '../constants';
import { AutoRecognitionDataType, AutoRecognitionOrientationData } from '../types';

export const getRotationAngle = (direction: Directions, angle: Angle) => {
    const relativeAngle = direction === Directions.Clockwise ? FULL_ROTATION - angle : angle;
    return Math.abs(FULL_ROTATION - relativeAngle);
};

export const getSizeRanking = (autoRecognitionData: AutoRecognitionDataType | null, size: string) => {
    if (!autoRecognitionData) {
        return;
    }

    const index = autoRecognitionData.bestFits.findIndex((bestFit) => bestFit === size);
    return index === -1 ? 'Others' : index + 1;
};

export const getCompatibleOrientationData = async (
    imageURL: string,
    compatibleOrientations: string[],
    selectedOptions: Record<string, string>,
    authorizationHeader: string
): Promise<AutoRecognitionOrientationData> => {
    const imageMindInfo = await getProcessedImageMindInfo(imageURL, authorizationHeader);

    return imageMindInfo && compatibleOrientations.includes(imageMindInfo.orientation)
        ? imageMindInfo
        : {
              orientation: selectedOptions[ProductAttributes.Orientation],
          };
};

export const getAutoRecognitionCase = (oldOption: Record<string, string>, sizeAttribute: string) => {
    if (!oldOption[sizeAttribute] && !oldOption[ProductAttributes.Orientation]) {
        return autoRecognitionCases[1]; // Case - 1: when no selection on PDP
    }

    if (oldOption[ProductAttributes.Orientation] && !oldOption[sizeAttribute]) {
        return autoRecognitionCases[2]; // Case - 2: when user has only selected orientation
    }

    if (oldOption[sizeAttribute] && !oldOption[ProductAttributes.Orientation]) {
        return autoRecognitionCases[3]; // Case - 3: when user has only selected size
    }

    return autoRecognitionCases[4]; // Case - 4: when user has selected both orientation and size
};

export const getAutoRecognitionNotification = (
    sizeAttribute: string,
    autoRecognitionData: AutoRecognitionDataType,
    productData: ProductData,
    changeSizePrices: ChoiceGroupPriceDetails,
    getTranslatedOptionName: (designAttributeName: string, designAttributeValue: string) => string
) => {
    const {
        productName,
        quantity,
        customerSelectedOptions: { [ProductAttributes.Orientation]: currentOrientation, [sizeAttribute]: currentSize },
    } = productData;
    const { bestFits, preSelected, detectedOrientation } = autoRecognitionData;
    const size = bestFits.length ? bestFits[0] : preSelected;
    const { totalDiscountPrice: price } =
        changeSizePrices[detectedOrientation ? `${detectedOrientation}-${size}` : preSelected];

    const isOrientationMismatched = currentOrientation !== detectedOrientation;

    const autoRecognitionCase = getAutoRecognitionCase(
        {
            [sizeAttribute]: currentSize,
            [ProductAttributes.Orientation]: currentOrientation,
        },
        sizeAttribute
    );

    const translatedSizeValue = getTranslatedOptionName(sizeAttribute, preSelected);
    const translatedOrientationValue = getTranslatedOptionName(ProductAttributes.Orientation, detectedOrientation);

    if (autoRecognitionCase === autoRecognitionCases[2] && isOrientationMismatched) {
        return text('updateOrientation', { newOrientation: translatedOrientationValue });
    }

    if (autoRecognitionCase === autoRecognitionCases[3]) {
        return text('changedProduct', {
            productName,
            orientation: translatedOrientationValue,
            attributeValue: translatedSizeValue,
            price,
            quantity,
        });
    }

    return text('artboardCreate', { attributeValue: translatedSizeValue, price, quantity });
};
