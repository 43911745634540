import { fireImpression } from '@vp/ab-reader';
import { noticeError } from './newRelicUtils';
import { Context } from '../api/siteFlow';
import experiments from '../config/experiments.json';
import { QuadExperiment } from '../types/quadExperiment';

export const experimentFilter = (locale: string, mpvId: string) => (experiment: QuadExperiment) => {
    const { supportedLocales, supportedMpvIds } = experiment;

    // mandatory fields for experiment filters
    return supportedLocales.includes(locale) && supportedMpvIds.includes(mpvId);
};

export const getQUADExperiments = (locale: string, mpvId: string) => {
    const lowerCaseLocale = locale.toLowerCase();
    const [experiment] = (experiments as QuadExperiment[]).filter(experimentFilter(lowerCaseLocale, mpvId));

    return experiment;
};

// fireImpressionsForContexts is an utility to fire impressions for all the experiments
// considered as viewed at the moment the user navigates to the next page
/**
 * Fires impressions for the given experiment and variation contexts.
 *
 * @param contexts - An array of experiment and variation contexts to fire impressions for.
 * @returns A promise that resolves when all impressions have been fired, or silently fails if any impression fails to fire.
 */
export async function fireImpressionsForContexts(contexts: Context[]): Promise<void> {
    if (contexts.length > 0) {
        try {
            await Promise.all(contexts.map((context) => fireImpression(context.experiment, context.variation)));
        } catch (err) {
            noticeError(err, { method: 'fireImpressionsForContexts' });
        }
    }
}
