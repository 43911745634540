import { MaskType } from '@design-stack-vista/ida-framework';
import { tokens } from '@vp/swan';

export const enum Tenants {
    Vistaprint = 'vistaprint',
    VistaprintProd = 'vistaprint-prod',
}

export const VORTEX_TENANT = { id: Tenants.VistaprintProd, type: 'merchants' };

export const enum PanelSides {
    Front = 'front',
    Back = 'back',
}

export const enum Mask {
    Bleed = 'BLEED',
    Trim = 'TRIM',
    Safe = 'SAFE',
}

export const enum ProductAttributes {
    Orientation = 'Product Orientation',
    DecorationArea = 'Decoration Area',
    DesignableArea = 'Designable Area',
    Size = 'Size',
    SubstrateColor = 'Substrate Color',
    Shape = 'Shape',
}

export const enum PricingType {
    Taxed = 'taxed',
    Untaxed = 'untaxed',
}

// lists all consumers of QUAD
export const enum QuadConsumer {
    VCS = 'vcs-quad-studio',
}

export const enum ProductConfigurationStatus {
    Loading = 'loading',
    Switched = 'switched',
    Success = 'success',
    Error = 'error',
}

export enum CustomURLParams {
    StartingCanvas = 'startingCanvas',
    ActiveDialogPanel = 'activeDialogPanel',
}

export const enum CustomURLParamValues {
    GroupAndTeams = 'groupsAndTeams',
}

//  https://gitlab.com/Cimpress-Technology/DocDesign/designexperience/cimpress-designer/-/blob/master/app/publicApi/constants/processTypes.js
export const enum ProcessType {
    None = 'none',
    Offset = 'offset',
    Digital = 'digital',
    OffsetOrDigital = 'offsetOrDigital',
    DirectToGarment = 'directToGarment',
    HeatTransfer = 'heatTransfer',
    LaserEngraving = 'laserEngraving',
    ScreenPrint = 'screenPrint',
    PadPrint = 'padPrint',
    InkJet = 'inkJet',
    Thermography = 'thermography',
    EmbroideryFlat = 'embroideryFlat',
    EmbroideryCylinder = 'embroideryCylinder',
    Sublimation = 'sublimation',
    Unknown = 'unknown',
}

export const enum ModalVariants {
    PanelBottom = 'panel-bottom',
    PanelRight = 'panel-right',
    PanelLeft = 'panel-left',
    PanelTop = 'panel-top',
    Menu = 'menu',
    Standard = 'standard',
}

export const APPLICATION_NAME = 'quick_upload-and-design';

export const PREVIEW_SIZE = {
    width: 500,
    height: 500,
};

export const REQUESTOR = process.env.REACT_APP_REQUESTOR || 'quad';

export const ARTWORK_GENERATION_API_KEY = 'JHmdT3A2y1qpLJaCfqq7Oz2EpKejP6GNc6H';

export const STUDIO_PREVIEW_PURPOSE = 'design.studio.review';

export const STUDIO_CALCIFER_URL = process.env.REACT_APP_CALCIFER_ROUTE;

export const PRICING_NOTIFICATION_DISMISS_TIMEOUT = 10000;
export const NOTIFICATION_DISMISS_TIMEOUT = 5000;

export const FRONT = 'Front';
export const BACK = 'Back';

export const MAX_FILE_SIZE_IN_KB = 204800; // 200 MB

export type CalloutSkins = 'accent' | 'standard' | 'success';

type DesignMaskConfigurationType = {
    [key in MaskType]: {
        enabled: boolean;
        backgroundColor: string;
        calloutSkin: CalloutSkins;
    };
};

export const DESIGN_MASK_CONFIGURATION: DesignMaskConfigurationType = {
    [Mask.Bleed]: {
        enabled: true,
        backgroundColor: tokens.SwanSemColorIconAccent,
        calloutSkin: 'accent',
    },
    [Mask.Trim]: {
        enabled: false,
        backgroundColor: tokens.SwanSemSizeIconStandard,
        calloutSkin: 'standard',
    },
    [Mask.Safe]: {
        enabled: true,
        backgroundColor: tokens.SwanSemColorIconSuccess,
        calloutSkin: 'success',
    },
};

export const PREVIEW_BUTTON_HEIGHT = '59px';

//zoom constants
export const NUDGE_OFFSET = 20;
export enum Zoom {
    MAX_VALUE = 3,
    MIN_VALUE = 0.25,
    DEFAULT_VALUE = 1,
    MODIFY_VALUE = 0.05,
    SCALE_VALUE = 100,
}
export const ZOOM_THRESHOLD_TO_SHOW_ICON = 0.5;

export const SingleColorTypes = [ProcessType.PadPrint, ProcessType.LaserEngraving, ProcessType.ScreenPrint];
export const DEFAULT_STARTING_CANVAS = '1';
